<template>
<v-app>
  <v-app-bar app>
    <!-- -->
    wonderland calculator
  </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer app  style="position:relative!important;">
    
    <v-col
      
      cols="12"
     class="text-center"
      >
       wonderland calculator — handcrafted by <strong>MasterB</strong><br />
    together deep down the rabbit hole - Buy me a beer (ERC20, AVAX, BSC): <br /><strong><small>0x963f09612a2fa5040CE2C5366E7415d05b2Eb1c4</small></strong><br />
    interested in dev support? telegram: <a href="https://t.me/MasterB_81">MasterB_81</a>  | email: <a href="mailto:masterb@wonderland-calculator.org">masterb@wonderland-calculator.org</a>
    </v-col>
  </v-footer>
</v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
