<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-3 ">
          Calculate your $TIME earnings
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="mb-1"
        cols="6"
        sm="3"
      >  <v-text-field
            label="Amount time"
            placeholder="1.0"
            outlined
            type="number"
            v-model="amount"
          ></v-text-field>
      </v-col>
       <v-col
        class="mb-1"
         cols="6"
        sm="3"
      >  <v-text-field
            label="Current APY in %"
            placeholder="70000"
            outlined
            type="number"
            v-model="currentAPY"
          ></v-text-field>
      </v-col>
       <v-col
        class="mb-1"
         cols="6"
        sm="3"
      >  <v-text-field
            label="Price per $TIME"
            placeholder="7000"
            outlined
            type="number"
            v-model="realPrice"
          ></v-text-field>
       </v-col>
         <v-col
        class="mb-1"
        cols="6"
        sm="3"
      >
          <v-text-field
            label="Timespan (in days) "
            placeholder="100"
            outlined
            type="number"
            v-model="timespan"
          ></v-text-field>
      </v-col>
      </v-row>
     

    <v-row justify="center">
     <v-col cols="12" sm="8"> <v-sheet elevation="1" outlined rounded class="pa-4 mb-6" >
        <h2 class="text-center font-weight-light">in <span class="font-weight-black" style="font-size: xx-large;">{{timespan}}</span> days you will have <span class="font-weight-black" style="font-size: xx-large;">{{calculateAmount(this.timespan)}}</span> $TIME (${{ calculateAmountinDollar(this.timespan) }})</h2>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <h3 class="text-center">in 30 days you will have {{calculateAmount(30)}} |  $TIME (${{ calculateAmountinDollar(30) }})</h3>
      </v-col>
       <v-col cols="12">
        <h3 class="text-center">in 90 days you will have {{calculateAmount(90)}} $TIME (${{ calculateAmountinDollar(90) }})</h3>
       </v-col>
       <v-col cols="12">
        <h3 class="text-center">in 180 days you will have {{calculateAmount(180)}} $TIME (${{ calculateAmountinDollar(180) }})</h3>
       </v-col>
        <v-col cols="12" class="mb-12">
        <h3 class="text-center">in 365 days you will have {{calculateAmount(365)}} $TIME (${{ calculateAmountinDollar(365) }})</h3>
       </v-col>
       <v-col cols="12">
         <img src="" />
       </v-col>
       <v-col cols="12" class="mb-1 text-center">
        <small> Disclaimer: This is an unofficial hobby project for <a href="https://wonderland.money" target="_BLANK">Wonderland</a> - Use at your own risk. 
         I am not directly involved in the project.</small>
       </v-col>
      
    </v-row>

  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'HelloWorld',
    data: () => ({
      currentAPY: 93000,
      amount: 1,
      realPrice:1,
      timespan:10,
      rewardYield:0.60
    }),
    computed: {
    },
    methods: {
      //Calculate future Value of Time based on reward Yield
      //Formula for Compounded interest: A=P(1+r/m)^m -> Future Value = Principal(1+apr/number of times compounded)^number of times compounded
      //E.G.:1000$, APR 3%, monthly compounded: FV= 1000(1+0,03/12)^12, https://www.mathbootcamps.com/compound-interest-formula/
      //Returns Time Value after x days
      calculateAmount(days) {
        let num= (this.amount*Math.pow(1+this.calculateRewardYield(this.calculateAPRfromAPY(this.currentAPY))/100,days*3)).toFixed(2);
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      //returns $-value of staked time after x days
       calculateAmountinDollar(time) {
        return parseInt(this.calculateAmount(time)*this.realPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      calculateAPRfromAPY(APY) {
        let apr=APY/100;
        apr=apr+1;
        apr=Math.pow(apr,1/1095);
        apr=apr-1;
        apr=apr*1095;
        apr=apr*100;
        return apr;
      },
      calculateRewardYield(APR) {
        //The RewardYield per rebase: APR/365 = daily Reward, daily Reward/3= Rebase Reward
        let rebaseReward=APR/365;
        rebaseReward=rebaseReward/3;
        return rebaseReward;
      },
    },
      // Fetches Wonderland Price from API data when the component is created.
  created() {
    axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=wonderland&vs_currencies=usd`)
    .then(response => {
      // JSON responses are automatically parsed.
      this.realPrice = response.data.wonderland.usd;
    })
    .catch(e => {
      this.errors.push(e)
    })
  }
  }
</script>
